// Node Modules
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Layout from "../components/Layout/Layout";
import Seo from '../components/Seo';
import ContactForm from "../components/Contact"
import MapWidget from "../components/GoogleMap"
import CalendarWidget from "../components/Calendar"

// Assets
import bgGrass from '../images/background-header-grass-70.svg'

const GeneralPage = ({ data }) => {
  const page = data.pagedata;
  const accommodation = data.accommodation

  const divText = {
    gridArea: "1/1",
    position: "relative",
    placeItems: "end",
    display: "grid"
  };

  return (
    <>
      <Layout>
        <Seo strTitle={page.frontmatter.seotitle} strDescription={page.frontmatter.seometadesc} strImage={page.frontmatter.featuredimage.publicURL} />
        <div className="h-80 w-full md:h-425 lg:h-465 xl:h-515" style={{backgroundImage: `url(${bgGrass})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
            <div className="grid h-full relative overflow-hidden">
                <GatsbyImage image={page.frontmatter.featuredimage.childImageSharp.gatsbyImageData} alt={page.frontmatter.featuredimagealt}
                  className="row-start-1 col-start-1" style={{maxHeight: "515px", zIndex: "-10"}}
                  layout="fullWidth"
                  aspectratio={3 / 1}
                />
            </div>
        </div>
        <div className="">
            <div className="container mx-auto">
                <div style={divText} className="flex justify-around">
                    <div className="text-center font-cursive max-w-screen-lg">
                        <h1 className="text-template-accent tracking-wider text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-normal py-3">{page.frontmatter.title}</h1>
                    </div>
                </div>
                {page.frontmatter.contactform===2 &&(
                    <ContactForm />
                )}
                {page.frontmatter.mapwidget===2 &&(
                    <MapWidget />
                )}
                {page.frontmatter.calendarwidget===2 &&(
                    <CalendarWidget accommodation={accommodation} />
                )}
                <div className="flex justify-around py-6 px-2">
                    <div className="max-w-screen-lg cmscontent" dangerouslySetInnerHTML={{ __html: page.html}}>
                    </div>
                </div>
                {page.frontmatter.contactform===3 &&(
                    <ContactForm />
                )}
                {page.frontmatter.mapwidget===3 &&(
                    <MapWidget />
                )}
                {page.frontmatter.calendarwidget===3 &&(
                    <CalendarWidget accommodation={accommodation} />
                )}
            </div>
        </div>
      </Layout>
    </>
  );
};

export default GeneralPage;

export const query = graphql`
  query ($slug: String!) {
    pagedata: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
      frontmatter {
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FULL_WIDTH
            )
          }
          ext
          publicURL
        }
        featuredimagealt
        title
        seotitle
        seometadesc
        contactform
        mapwidget
        calendarwidget
      }
    }
    accommodation: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(accommodation)/.*[.]md$/" }
        frontmatter: { title: { ne: "dummy-acc-01-02-1999" } }
      }
      sort: { order: ASC, fields: [frontmatter___datepub] }
    ) {
      edges {
        node {
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  layout: FULL_WIDTH
                )
              }
              ext
              publicURL
            }
            featuredimagealt
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import logo from "../images/walkers-cart-lodge-logo.svg"

const MapPin = () => (
  <div style={{
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)'
  }}>
      <img width="25px" src={logo} alt="Walkers Cart Lodge" title="Walkers Cart Lodge" />
</div>)

class GoogleMap extends Component {
    static defaultProps = {
      center: {
        lat: 51.900359,
        lng: 0.143347
      },
      zoom: 12
    }
    render() {
      return (
        // Important! Always set the container height explicitly
        <div className="container mx-auto flex justify-around py-8">
            <div className="w-full h-full max-w-screen-md">
                <div className="panel p-3">
                    <p className="text top-left text-lg md:text-xl z-20">
                        {this.props.maptitle}
                    </p>
                    <div style={{ height: '40vh', width: '100%', maxHeight: "400px" }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyDTTMe2qYTTm8XM-dkQVmlDWRggxEMKgy0' }}
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                            options={{disableDefaultUI: false}}
                        >
                            <MapPin
                            lat={this.props.center.lat}
                            lng={this.props.center.lng}
                            />
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        </div>
      );
    }
  }
  
  export default GoogleMap;
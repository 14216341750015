import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

const CalendarWidget = (props) => {
    const { bookings, settingsgeneral } = useStaticQuery(
        graphql`
          query {
            bookings: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(bookings)/.*[.]md$/"}}) {
                edges {
                  node {
                    frontmatter {
                      accomodation
                      start
                      end
                    }
                  }
                }
              }
            settingsgeneral: file(relativePath: { eq: "settings/general.md" }) {
                childMarkdownRemark {
                    frontmatter {
                        settingheadercalendartxt
                    }
                }
            }
          }
        `
      );

    const localizer = momentLocalizer(moment)

    return (
        <>
        <div className="container mx-auto">
            <div className="cmscontent flex justify-around"><h2>{settingsgeneral.childMarkdownRemark.frontmatter.settingheadercalendartxt}</h2></div>
        </div>
        {props.accommodation.edges.map((edge, index) => {
            const frontmatter = edge.node.frontmatter
            let events = []
            bookings.edges.filter(booking => booking.node.frontmatter.accomodation === frontmatter.title).map(booking => {
                const start = new Date(booking.node.frontmatter.start.replace(/-/g, "/"))
                const end = new Date(booking.node.frontmatter.end.replace(/-/g, "/")) 
                return (
                    events.push({'title':'Booked', 'start': start, 'end': end})
                )        
            })
            return (
            <div className="container mx-auto" key={index}>
                <div className="flex justify-around px-2">
                    <div className="max-w-screen-lg w-full flex justify-around px-2 flex-col md:flex-row">
                        <div className="hidden md:w-1/2 m-6 bg-black md:flex flex-wrap content-center" style={{backgroundImage: `url(${frontmatter.featuredimage.publicURL})`, backgroundSize: "cover"}}>
                            <div className="w-full text-center text-white font-cursive text-2xl">{frontmatter.title}</div>
                        </div>
                        <div className="py-6 md:pr-6 w-full md:w-1/2">
                            <div className="md:hidden w-full text-center font-cursive text-2xl text-template-accent pb-3">
                                {frontmatter.title}
                            </div>
                            <Calendar
                                eventPropGetter={
                                    (event, start, end, isSelected) => {
                                    let newStyle = {
                                        backgroundColor: "#6Ba4B8",
                                        color: 'white',
                                        borderRadius: "0px",
                                        border: "none",
                                        paddingBottom: "20px",
                                        fontSize: "0.7rem"
                                    };
                                    return {
                                        className: "",
                                        style: newStyle
                                    };
                                    }
                                }
                                selectable="ignoreEvents"
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                views={['month']}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )})}
        <div className="container mx-auto flex justify-around mb-6">
            <div className="py-3 px-6 bg-template-accent text-white mx-2">
                <Link to="/contact/">
                    Contact Us to Book
                </Link>
            </div>
        </div>
        </>
    )
}

export default CalendarWidget
import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

const ContactForm = () => {
    const { settingsgeneral, settingscontact } = useStaticQuery(graphql`
      query {
        settingsgeneral: file(relativePath: {eq: "settings/general.md"}) {
            childMarkdownRemark {
              frontmatter {
                settingsitetwitteruser
                settingsitefacebookuser
                settingsiteinstagramuser
                settingsitelinkedin
                settingsiteorg
                settingsitetelephone
                settingsiteemail
              }
            }
          }
          settingscontact: file(relativePath: {eq: "settings/contact.md"}) {
            childMarkdownRemark {
              frontmatter {
                settingconttitle
                settingcontheading
                settingcontformnametxt
                settingcontformemailtxt
                settingcontformteltxt
                settingcontformmsgtxt
                settingcontformbtntxt
                settingcontformconstxt
                settingcontformsendto
                settingcontformenemail
                settingcontformentel
              }
            }
          }
      }
    `)
    const settingsgen = settingsgeneral.childMarkdownRemark.frontmatter
    const settingcont = settingscontact.childMarkdownRemark.frontmatter
    return (
        <>
        <section className="text-gray-600 relative font-cursive">
            <div className="container px-5 py-8 mx-auto max-w-screen-lg">
                <div className="flex flex-col text-center w-full mb-12">
                    <h2 className="sm:text-3xl text-2xl font-light mb-4 text-template-accent">{settingcont.settingconttitle}</h2>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">{settingcont.settingcontheading}</p>
                </div>
                <div className="md:w-4/5 mx-auto">
                    <form className="w-full"
                        name="Contact"
                        method="post"
                        data-netlify="true"
                        action="/thank-you/">
                        <input type="hidden" name="form-name" value="Contact" />
                        <input type="hidden" name="send_to" id="send_to" value={settingcont.settingcontformsendto} />
                        <input type="hidden" name="website_org" id="website_org" value={settingsgen.settingsiteorg} />
                        <div className="flex flex-wrap -m-2">
                                <div className="p-2 w-full">
                                    <div className="relative">
                                        <label className="leading-7 text-sm text-gray-600">{settingcont.settingcontformnametxt}
                                        <input type="text" required id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                        </label>
                                    </div>
                                </div>
                                <div className="p-2 w-1/2">
                                    <div className="relative">
                                        <label className="leading-7 text-sm text-gray-600">{settingcont.settingcontformemailtxt}
                                        <input type="email" required id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                        </label>
                                    </div>
                                </div>
                                <div className="p-2 w-1/2">
                                    <div className="relative">
                                        <label className="leading-7 text-sm text-gray-600">{settingcont.settingcontformteltxt}
                                        <input type="tel" required id="telephone" name="telephone" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                        </label>
                                    </div>
                                </div>
                                <div className="p-2 w-full">
                                    <div className="relative">
                                        <label className="leading-7 text-sm text-gray-600">{settingcont.settingcontformmsgtxt}
                                        <textarea id="message" required name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                                        </label>
                                    </div>
                                </div>
                                <div className="p-2 w-full">
                                    <label className="block cmscontent">
                                        <input className="m-2 inline-block" name="gdpr" id="gdpr" type="checkbox" required />
                                        <small>
                                            {settingcont.settingcontformconstxt}
                                        </small>
                                    </label>
                                </div>
                                <div className="p-2 w-full flex justify-around">
                                    <button type="submit" className="ctabutton text-white bg-template-accent 
                                        border-0 py-2 px-6 focus:outline-none
                                        hover:bg-template-orangeLight text-sm md:text-base">{settingcont.settingcontformbtntxt}</button>
                                </div>
                                <div className="p-2 w-full mt-2 border-t border-gray-200 text-center">
                                    <div className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                                    {settingsgen.settingsitefacebookuser &&
                                        <a href={settingsgen.settingsitefacebookuser} className="text-gray-500" target="_blank" rel="noreferrer" aria-label="Facebook">
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                        </svg>
                                        </a>
                                    }
                                    {settingsgen.settingsitetwitteruser &&
                                        <a href={settingsgen.settingsitetwitteruser} className="ml-3 text-gray-500" target="_blank" rel="noreferrer" aria-label="Twitter">
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                        </svg>
                                        </a>
                                    }
                                    {settingsgen.settingsiteinstagramuser &&
                                        <a href={settingsgen.settingsiteinstagramuser} className="ml-3 text-gray-500" target="_blank" rel="noreferrer" aria-label="Instagram">
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                        </svg>
                                        </a>
                                    }
                                    {settingsgen.settingsitelinkedin &&
                                        <a href={settingsgen.settingsitelinkedin} className="ml-3 text-gray-500" target="_blank" rel="noreferrer" aria-label="Linkedin">
                                        <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                            <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                        </svg>
                                        </a>
                                    }
                                    </div>
                                </div>
                        </div>
                    </form>
                    <div className="flex flex-col justify-center text-center cmscontent">
                        {settingcont.settingcontformentel === true &&(
                            <div>Telephone: <a href={`tel:${settingsgen.settingsitetelephone}`}>{settingsgen.settingsitetelephone}</a></div>
                        )}
                        {settingcont.settingcontformenemail === true &&(
                            <div>Email: <a href={`mailto:${settingsgen.settingsiteemail}`}>{settingsgen.settingsiteemail}</a></div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default ContactForm